@import '../grid/_gird';
@import '../typography/_typo';
@import '../colors/colors.scss';
@import '../components/where-to-buy.scss';
.footer {
    background-color: color(DarkBue);
    padding: 115px 0 50px 0;
    font-family: 'open sans';
    text-align: center;

    // @include md-screen {
    //     padding: 24px 0;
    // }

    @include lg-screen {
        padding: 116px 0 165px 0;
    }
    .footer-top{
        margin-bottom: 52px;
        @include md-screen{
            margin-bottom: 83px;
        }
        h2{
            @include lg-screen{
                margin-top: 30px;
            }
            
        }
    }
    .Partners{
        margin-bottom: 25px;
            @include md-screen{
            margin-bottom: 68px;
            }
            .Partners__link{
                margin-bottom: 27px;
                @include md-screen{
                    margin-bottom: 0px;
                    }
            }
    }
    .footer__h2{
        margin-bottom: 37px;
            @include md-screen{
                margin-bottom: 22px;
                }
    }
    .footer__h4{
        margin-bottom: 37px;
            @include md-screen{
                margin-bottom: 24px;
                }
    }
    .center{
        text-align: center;
    }
}
.pages_link_wrapper {
    display: flex;
    justify-content: space-around;
    list-style: none;
}
.footer-page-link{
    color: #fff;
    text-decoration: none;
    &:hover{
        color: #fff;
        text-decoration: none;
    }
}
.footer_list {
    display: flex;
    justify-content: center;
    list-style: none;
    margin-bottom: 16px;
    padding-left: 0;
}
.social_media_item{
    margin-right: 8px;
}
.social_media_title {
    color: #fff;
margin-top: 24px;
font-weight: 600;
font-size: 24px;
margin-bottom: 16px;
}
.icon-link-wrap img{
    width: 25px;
    height: 25px;
}
@media (max-width:991px){
    .pages_link_wrapper {
     
        padding-left: 0;
        flex-direction: column;
    }
    .footer-menu-item +.footer-menu-item{
        margin-top: 8px;
    }
    .footer .Partners{
        margin-bottom: 0;
    }
    .footer .footer-top {
        margin-bottom: 27px;
    }
    .footer-top h2{
        padding-top: 27px;
    }
}
//     .connect-us,
//     .quick-links,
//     .copyrights,
//     .termsofuse {
//         padding-left: 16px;
//         padding-right: 16px;

//         @include md-screen {
//             padding-left: 0px;
//             padding-right: 0px;
//         }

//     }

//     .quick-links {
//         margin-bottom: 8px;

//         @include md-screen {
//             margin-bottom: 0px;
//         }

//     }

//     .social-icons__icon {
//         font-size: 32px;
        
//     }
//     .social-icons__link{
//         margin-right: 16px;
//         color:color(BlackColor);
//     }


//     .connect-us__header--h4 {
//         font-size: 22px;
//         margin-bottom: 16px;
//         line-height: 28px;
//     }

//     .connect-us {
//         margin-bottom: 24px;

//         @include md-screen {
//             margin-bottom: 40px;
//         }
//     }

//     .quick-links__title {
//         font-size: 16px;
//         font-weight: normal;
//         margin-bottom: 16px;
//         line-height: 20px;
//         // margin-top: 24px;
//     }

//     .quick-links__link,
//     .termsofuse__link {
//         text-decoration: none;
//         color: #000;
//         font-weight: 300;
//     }

//     .quick-links__link {
//         font-size: 16px;
//         margin-bottom: 16px;
//         line-height: 20px;

//     }

//     .quick-links__link:hover,
//     .termsofuse__link:hover {
//         color: color(RedColor);

//     }

//     .termsofuse__link {
//         margin-right: 8px;
//         font-size: 14px;
//         margin-bottom: 8px;
//         line-height: 18px;


//     }


//     .quick-links__divider--vr {
//         font-size: 14px;
//         font-weight: 300;
//         line-height: normal;
//         margin-right: 8px;
//     }

//     .termsofuse {
//         @include md-screen {
//             margin-top: 16px;

//         }

//         @include lg-screen {
//             margin-top: 24px;
//         }

//     }

//     .copyrights__text {
//         font-size: 14px;
//         font-weight: 300;
//         // margin-top: 8px;
//         line-height: 18px;
//     }

//     .copyrights__text:last-child {
//         margin-top: 8px;

//         @include md-screen {
//             margin-top: 0px;
//         }
//     }


// }
// .footer .quick-links__divider--vr:last-of-type {
//     display: none;
// }
// .social-icons__link img + img, .social-icons__link:hover img {
//     display: none;
//   }
//   .social-icons__link:hover img + img {
//     display: inline-block !important;
   
 