@import '../grid/_gird';
@import '../typography/_typo';
// @import '../typography.scss';
@import '../colors/colors.scss';

.where-to-buy{
    text-align: center;
    background-color: color(purpleColor);
    color: color(WhiteColor);
    padding:43px 0 57px;
    background-size: cover !important;
background-position: center !important;
    @include md-screen{
        text-align: left;
        padding:90px 0 46px;
    }

    a:hover{
        text-decoration: none;
        color:color(WhiteColor);
    }
    .where-to-buy__RightSection{

        @include md-screen{
            
        }
        .where-to-buy__h2{
            margin-bottom: 37px;
            @include md-screen{
                margin-bottom: 30px;
            }
        }
        .where-to-buy__h3{
            margin-bottom: 26px;
            @include md-screen{
                margin-bottom: 30px;
            }
        }
        .where-to-buy__div{
        p + p, p +.row{
            margin-top: 26px;
            @include md-screen{
                margin-top: 30px;
            }
        }
    }
        .btn__blue{
           background-color: color(BlueColor);
           text-align: center;
           border-radius: 44px;
           padding: 19px 42px;
           margin-top: 27px;

           @include md-screen{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 15px 0;
            font-size: 23px;
            margin-top: 0px;
           }
           @include lg-screen{
            padding: 20px 0px;
            font-size: 27px;
           }
           

        }
        .mt-27px{
            margin-top: 27px;
            @include md-screen{
                margin-top: 0;
            }
        }
        .btn__amazon {
            background-color: color(DarkGrey) !important;
            color:color(WhiteColor);
            border:0;
            padding: 18px 0px !important;
            @include md-screen{
            padding: 18px 33px !important;
            }
         img{
            width: 60%;
            max-width: 75%;
            @include md-screen{
                width: 100%;   
            }
        }
       }

    }
    .where-to-buy__leftSection{
        img{
            width:30%;
            @include md-screen{
                width:80%;
            }
            @include lg-screen{
                width:auto;
            }
        }
        
    }
}
.center-where-to-buy{
    margin-left: auto;
    margin-right: auto;
    @include lg-screen{
        margin-left: 0;
        margin-right: 0;
    }
}